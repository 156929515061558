import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SolanaLogo from "../../../assets/images/solana-sol-logo.png"
import NetworkDetails from "../../components/network-details";

const Solana = () => (
  <Layout>
    <Seo title="Solana" />
    <NetworkDetails
      logo={SolanaLogo}
      name="sol"
      commission="7%"
      totalStake="32,370 SOL"
      address="8HKqT579dAjdTy86zKUs8kAaGDHXY11wDC3ohGCkLSQH"
    />
    <Link
      className="orange-button back-to-homepage"
      style={{
        maxWidth: `45%`,
        margin: `80px auto 0`,
        textAlign: `center`,
        display: `block`,
        textDecoration: `none`
      }}
      to="/#networks"
    >
      Back to homepage
    </Link>
  </Layout>
)

export default Solana
